<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        စာရင်း အားလုံးပေါင်း
                        <h5>
                            <span class="badge badge-secondary mr-2">ခုရေ: {{calcData.count}} ခု</span>
                            <span class="badge badge-secondary mr-2">ချေးငွေ: {{calcData.totalloan}} ကျပ်</span>
                            <span class="badge badge-secondary mr-2">အတိုး: {{calcData.interest}} ကျပ်</span>
                            <span class="badge badge-secondary mr-2">တိုးရင်းပေါင်း: {{calcData.totalinterest}} ကျပ်</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="th-width-200">ဆိုင်ခွဲ အမည်</th>
                                                <th v-for="(value, key) in columns" class="th-width-200"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <!-- <th class="th-width-100">ကာလ</th>
                                                <th class="th-width-100">အတိုး</th>
                                                <th class="th-width-100">တိုးရင်းပေါင်း</th> -->
                                                <th class="th-width-100">အခြေအနေ</th>
                                                <th class="th-width-100">လက်မှတ်ပျောက်</th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td>{{data.shop}}</td>
                                                <td>
                                                    <span>{{data.no_date}} </span>
                                                    <span class="badge badge-primary">
                                                        <span v-if="data.no_type != 'No'">
                                                        {{data.no_type}}
                                                        </span>
                                                        {{data.no}}
                                                    </span>
                                                </td>
                                                <td>{{data.start_date}}</td>
                                                <td>{{data.loan}}</td>
                                                <td>{{data.type}}</td>
                                                <td><a href="javascript:void(0);" @click="getInfo(data)"
                                                        v-b-modal.bv-modal-address>{{data.name}}</a></td>
                                                <td>{{data.end_date}}</td>
                                                <td>
                                                    <span v-if="data.status == 'processing'">မရွေးရသေး</span>
                                                    <span v-if="data.status == 'completed'">ရွေးပြီး</span>
                                                    <span v-if="data.status == 'loss'">အပေါင်ဆုံး</span>
                                                </td>
                                                <td>
                                                    <button v-if="data.loss == 1" class="btn btn-option text-danger"
                                                        @click="printData(data)">
                                                        <i class="fas fa-print"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-option text-primary" @click="print(data)">
                                                        <i class="fas fa-print"></i>
                                                    </button>
                                                    <!-- <button class="btn btn-option text-primary"
                                                        @click="printData(data)">
                                                        <i class="fas fa-print"></i>
                                                    </button> -->
                                                    <button class="btn btn-option text-success">
                                                        <i class="fas fa-edit" @click="editData(data)"></i>
                                                    </button>
                                                     <button v-b-modal.bv-modal-delete
                                                        class="btn btn-option text-danger"><i
                                                            class="fas fa-trash-alt" @click="deleteData(data)"></i></button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="18" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">ပယ်ဖျက်ရန်သေချာပြီလား ? </p>
            <b-button class="mt-3 btn-danger" block @click="deleteMortgage()">လုပ်ဆောင်မည်</b-button>
        </b-modal>
        <b-modal id="bv-modal-address" ref="my-modal" title="အချက်အလက်" hide-footer>
            <p class="my-4"><span>မှတ်ပုံတင် အမှတ် : </span>{{infoList.nrc}}</p>
            <p class="my-4"><span>လိပ်စာ : </span>{{infoList.address}}</p>
            <p class="my-4"><span>အတိုးနှုန်း : </span>{{infoList.interest_rate}} %</p>
            <p class="my-4"><span>ကာလ : </span>{{infoList.total_day}} <span class="text-success"> {{infoList.pay_date}} </span></p>
            <p class="my-4"><span>အတိုး : </span>{{infoList.interest}} ကျပ်</p>
            <p class="my-4"><span>တိုးရင်းပေါင်း : </span>{{infoList.loan_interest}} ကျပ်</p>
            <div v-if="infoList.reference_status == 'yes'">
                <p class="my-4"><span>ဘောင်ချာ နံပါတ် : </span>{{infoList.reference_note}}</p>
                <p class="my-4" v-if="infoList.reference_type == 'type2'"><span>မှတ်ချက် : </span>{{infoList.old_loan}}
                </p>
                <p class="my-4" v-if="infoList.reference_type == 'type3'"><span>မှတ်ချက် :
                    </span>{{infoList.old_loan}}-{{infoList.reference_loan}}</p>
                <p class="my-4" v-if="infoList.reference_type == 'type4'"><span>မှတ်ချက် :
                    </span>{{infoList.old_loan}}+{{infoList.reference_loan}}</p>
            </div>
        </b-modal>
        <b-modal id="bv-modal-search" ref="my-modal" hide-footer>
            <template v-slot:modal-title>
                စီစစ်ရန်
            </template>
            <div class="d-block text-center">
                <select v-if="this.$role == 'admin'" id="inputItemId" class="form-control" v-model="filter.shop">
                    <option value="">ဆိုင်ခွဲ အားလုံး</option>
                    <option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
                </select><br>
                <select id="inputItemId" class="form-control" v-model="filter.item">
                    <option value="">ပစ္စည်း အမျိုးအစား အားလုံး</option>
                    <option v-for="item in itemList" :value="item.id">{{item.name}}</option>
                </select><br>
                <input onfocus="(this.type='month')" class="form-control" placeholder="ဘောင်ချာ နေ့စွဲ"
                    v-model="filter.nodate"><br>
                <select class="form-control" v-model="filter.notype" >
                    <option value="">ဘောင်ချာ အမျိုးအစား ရွေးချယ်မည်</option>
                    <option value="No">ဆိုင်ခွဲမရှိ</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                </select><br>
                <input type="number" class="form-control" placeholder="ဘောင်ချာ အမှတ်မှ့ ရှာရန်" v-model="filter.nofrom"><br>
                <input type="number" class="form-control" placeholder="ဘောင်ချာ အမှတ်ထိ ရှာရန်" v-model="filter.noto"><br>
                <input type="number" class="form-control" placeholder="ဘောင်ချာ အမှတ်" v-model="filter.no"><br>
                <input type="text" class="form-control" placeholder="ပေါင်နှံသူ အမည်" v-model="filter.name"><br>
                <input type="text" class="form-control" placeholder="နေရပ်လိပ်စာ" v-model="filter.address"><br>
                <input type="text" class="form-control" placeholder="ချေးငွေ" v-model="filter.loan"><br>
                <input onfocus="(this.type='date')" class="form-control" placeholder="နေ့စွဲမှ့ ရှာရန်"
                    v-model="filter.startdate"><br>
                <input onfocus="(this.type='date')" class="form-control" placeholder="နေ့စွဲထိ ရှာရန်"
                    v-model="filter.enddate"><br>
                <input onfocus="(this.type='date')" class="form-control" placeholder="အပေါင်ပြန်ရွေးသည့် နေ့စွဲမှ့ ရှာရန်"
                    v-model="filter.paydate"><br>
                <input onfocus="(this.type='date')" class="form-control" placeholder="အပေါင်ပြန်ရွေးသည့် နေ့စွဲထိ ရှာရန်"
                    v-model="filter.paydate_to"><br>
                <input onfocus="(this.type='date')" class="form-control" placeholder="သက်တမ်းကုန်ဆုံးမည့် နေ့စွဲမှ့ ရှာရန်"
                    v-model="filter.expdate_from"><br>
                <input onfocus="(this.type='date')" class="form-control" placeholder="သက်တမ်းကုန်ဆုံးမည့် နေ့စွဲထိ ရှာရန်"
                    v-model="filter.expdate_to"><br>
                <select class="form-control" v-model="filter.status">
                    <option value="">ဘောင်ချာ အခြေအနေ အားလုံး</option>
                    <option value="processing">လက်ကျန်</option>
                    <option value="completed">ရွေးပြီး</option>
                    <option value="loss">အပေါင်ဆုံး</option>
                </select><br>
                <select class="form-control" v-model="filter.referencestatus">
                    <option value="">ဘောင်ချာအဟောင်း</option>
                    <option value="yes">ရှိသည်</option>
                    <option value="no">မရှိပါ</option>
                </select><br>
                <select class="form-control" v-model="filter.otherstatus">
                    <option value="">အခြား အခြေအနေ အားလုံး</option>
                    <option value="keep">အကြီးသိမ်း</option>
                    <option value="loss">လက်မှတ်ပျောက်</option>
                </select><br>
                <select class="form-control" v-model="filter.inventoryMode">
                    <option value="">စာရင်းသွင်း အခြေအနေ အားလုံး</option>
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                </select>
            </div>
            <b-button class="mt-3 btn-success" block @click="search()">လုပ်ဆောင်မည်</b-button>
        </b-modal>
        <b-modal id="bv-modal-excel" ref="my-modal" title="ရွေးချယ်ပါ" hide-footer>
            <ul>
                <li v-for="(product, index) in excelColumns">
                    <input :value="product" name="product" type="checkbox" v-model="checkedProducts"
                        @change="getChech()" />
                    <label><span></span></label>
                </li>
            </ul>
        </b-modal>
        <!-- <a class="float-excel" to="">
            <export-excel  :data="json_data" :fields="json_fields" worksheet="My Worksheet"
                name="filename.xls">
                <i class="fas fa-file-excel my-float text-white" style="padding: 37%;bottom: 50px;"></i>
            </export-excel>

        </a> -->
        <a class="float" to="" v-b-modal.bv-modal-search>
            <i class="fa fa-filter my-float text-white" style="padding: 37%;bottom: 50px;"></i>
        </a>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
    import Printd from 'printd'
    export default {
        name: 'itemList',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                shopList: [],
                infoList: [],
                itemList: [],
                dataList: [],
                checkedProducts: [],
                excelColumns: {
                    name: "Complete name:name",
                    city: "City :city",
                },
                columns: {
                    no: "ဘောင်ချာ အမှတ်",
                    start_date: "ပေါင်နှံသည့်နေ့စွဲ ",
                    loan: "ချေးငွေ",
                    type: "ပစ္စည်းအမည်",
                    name: "ပေါင်နှံသူ အမည်",
                    end_date: "သက်တမ်းကုန်ဆုံးမည့်နေ့",
                },
                filter: {
                    page: 1,
                    reverse: "desc",
                    sort: "created_at",
                    liststatus: "all",
                    notype: "",
                    nofrom: "",
                    noto: "",
                    no: "",
                    nodate: "",
                    name: "",
                    address: "",
                    loan: "",
                    shop: "",
                    item: "",
                    startdate: "",
                    enddate: "",
                    mortgagedate: "",
                    paydate: "",
                    paydate_to: '',
					expdate_from:'',
					expdate_to:'',
                    status: "",
                    referencestatus: "",
                    otherstatus: "",
                    inventoryMode: ""
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null
                },
                calcData: {
                    totalloan: null,
                    totalinterest: null,
                    interest: null,
                    count: null,
                },
                json_fields: {
                    'ပေါင်နှံသည့်နေ့စွဲ': 'start_date',
                    'ဘောင်ချာ အမှတ်': 'no',
                    'ပေါင်နှံသူ အမည်': 'name',
                    'ပစ္စည်းအမည်': 'type',
                    'ချေးငွေ': 'loan',
                    'အလေးချိန် ( ကျပ် )': 'mortgage_weight_v1',
                    'အလေးချိန် ( ပြား )': 'mortgage_weight_v2',
                    'အလေးချိန် ( ရွေး )': 'mortgage_weight_v3',
                    'အခြေအနေ': 'status',
                    'အကြီးသိမ်း': 'keep',
                    'လက်မှတ်ပျောက်': 'loss',
                },
                json_data: [],
            }
        },
        watch: {
            '$route'(to, from) {
                this.fetchData()
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                mortgageClothFieldsAction: 'mortgageClothFieldsAction',
                mortgageClothListAction: 'mortgageClothListAction',
                mortgageClothCaclCountAction: 'mortgageClothCaclCountAction',
                mortgageClothExcelListAction: 'mortgageClothExcelListAction',
                mortgageClothDeleteAction: 'mortgageClothDeleteAction',
            }),
            getChech() {

            },
            sortBy(column) {
                this.filter.sort = column
                if (this.filter.sort == column) {
                    if (this.filter.reverse == "asc") {
                        this.filter.reverse = "desc"
                    } else if (this.filter.reverse == "desc") {
                        this.filter.reverse = "asc"
                    }
                } else {
                    this.filter.reverse = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
                this.calcCount()
                this.$refs['my-modal'].hide()
            },
            async excelData() {
                this.isLoading = true
                let option = {
                    sort: this.filter.sort,
                    reverse: this.filter.reverse,
                    page: this.filter.page,
                    liststatus: this.filter.liststatus,
                    notype: this.filter.notype,
                    nofrom: this.filter.nofrom,
                    noto: this.filter.noto,
                    no: this.filter.no,
                    name: this.filter.name,
                    address: this.filter.address,
                    loan: this.filter.loan,
                    shop: this.filter.shop,
                    item: this.filter.item,
                    startdate: this.filter.startdate,
                    enddate: this.filter.enddate,
                    status: this.filter.status,
                    referencestatus: this.filter.referencestatus,
                    nodate: this.filter.nodate,
                    paydate: this.filter.paydate,
                    paydate_to: this.filter.paydate_to,
                    expdate_from: this.filter.expdate_from,
                    expdate_to: this.filter.expdate_to,
                    otherstatus: this.filter.otherstatus,
                    inventoryMode: this.filter.inventoryMode

                }
                await this.mortgageClothExcelListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.json_data = res.data.data
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async calcCount() {
                let option = {
                    sort: this.filter.sort,
                    reverse: this.filter.reverse,
                    page: this.filter.page,
                    liststatus: this.filter.liststatus,
                    notype: this.filter.notype,
                    nofrom: this.filter.nofrom,
                    noto: this.filter.noto,
                    no: this.filter.no,
                    name: this.filter.name,
                    address: this.filter.address,
                    loan: this.filter.loan,
                    shop: this.filter.shop,
                    item: this.filter.item,
                    startdate: this.filter.startdate,
                    enddate: this.filter.enddate,
                    status: this.filter.status,
                    referencestatus: this.filter.referencestatus,
                    nodate: this.filter.nodate,
                    paydate: this.filter.paydate,
                    paydate_to: this.filter.paydate_to,
                    expdate_from: this.filter.expdate_from,
                    expdate_to: this.filter.expdate_to,
                    otherstatus: this.filter.otherstatus,
                    inventoryMode: this.filter.inventoryMode

                }
                await this.mortgageClothCaclCountAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.calcData.totalloan = res.data.totalLoan
                        this.calcData.totalinterest = res.data.totalinterest
                        this.calcData.interest = res.data.interest
                        this.calcData.count = res.data.totalCount
                    }
                }).catch()
            },
            async fetchData() {
                this.isLoading = true
                let option = {
                    sort: this.filter.sort,
                    reverse: this.filter.reverse,
                    page: this.filter.page,
                    liststatus: this.filter.liststatus,
                    notype: this.filter.notype,
                    nofrom: this.filter.nofrom,
                    noto: this.filter.noto,
                    no: this.filter.no,
                    name: this.filter.name,
                    address: this.filter.address,
                    loan: this.filter.loan,
                    shop: this.filter.shop,
                    item: this.filter.item,
                    startdate: this.filter.startdate,
                    enddate: this.filter.enddate,
                    status: this.filter.status,
                    referencestatus: this.filter.referencestatus,
                    nodate: this.filter.nodate,
                    paydate: this.filter.paydate,
                    paydate_to: this.filter.paydate_to,
                    expdate_from: this.filter.expdate_from,
                    expdate_to: this.filter.expdate_to,
                    otherstatus: this.filter.otherstatus,
                    inventoryMode: this.filter.inventoryMode

                }
                await this.mortgageClothListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.$router.replace({
                            path: 'mortgage-cloth-all-list',
                            query: {
                                page: this.filter.page,
                                reverse: this.filter.reverse,
                                sort: this.filter.sort,
                                liststatus: this.filter.liststatus,
                                notype: this.filter.notype,
                                nofrom: this.filter.nofrom,
                                noto: this.filter.noto,
                                no: this.filter.no,
                                name: this.filter.name,
                                address: this.filter.address,
                                loan: this.filter.loan,
                                shop: this.filter.shop,
                                item: this.filter.item,
                                startdate: this.filter.startdate,
                                enddate: this.filter.enddate,
                                status: this.filter.status,
                                referencestatus: this.filter.referencestatus,
                                nodate: this.filter.nodate,
                                paydate: this.filter.paydate,
                                paydate_to: this.filter.paydate_to,
                                expdate_from: this.filter.expdate_from,
                                expdate_to: this.filter.expdate_to,
                                otherstatus: this.filter.otherstatus,
                                inventoryMode: this.filter.inventoryMode
                            }
                        }).catch(() => {})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            async getFields() {
                let option = {
                    fields: {
                        type: "field"
                    }
                }
                await this.mortgageClothFieldsAction({
                    ...option
                }).then(res => {
                    this.shopList = res.data.data.shop
                    this.itemList = res.data.data.item
                }).catch(err => this.isLoading = true)
            },
            getInfo(data) {
                this.infoList = data
            },
            editData(data) {
                console.log(data)
                this.$router.replace({
                    path: 'mortgage-cloth-edit',
                    query: {
                        page: this.filter.page,
                        reverse: this.filter.reverse,
                        sort: this.filter.sort,
                        notype: this.filter.notype,
                        nofrom: this.filter.nofrom,
                        noto: this.filter.noto,
                        no: this.filter.no,
                        name: this.filter.name,
                        address: this.filter.address,
                        loan: this.filter.loan,
                        shop: this.filter.shop,
                        item: this.filter.item,
                        startdate: this.filter.startdate,
                        enddate: this.filter.enddate,
                        status: this.filter.status,
                        referencestatus: this.filter.referencestatus,
                        nodate: this.filter.nodate,
                        paydate: this.filter.paydate,
                        paydate_to: this.filter.paydate_to,
                        expdate_from: this.filter.expdate_from,
                        expdate_to: this.filter.expdate_to,
                        otherstatus: this.filter.otherstatus,
                        inventoryMode: this.filter.inventoryMode,
                        id: data.id
                    }
                }).catch(() => {})
            },
            print(data) {
                this.$router.replace({
                    path: 'cloth-print',
                    query: {
                        page: this.filter.page,
                        reverse: this.filter.reverse,
                        sort: this.filter.sort,
                        notype: this.filter.notype,
                        nofrom: this.filter.nofrom,
                        noto: this.filter.noto,
                        no: this.filter.no,
                        name: this.filter.name,
                        address: this.filter.address,
                        loan: this.filter.loan,
                        shop: this.filter.shop,
                        item: this.filter.item,
                        startdate: this.filter.startdate,
                        enddate: this.filter.enddate,
                        status: this.filter.status,
                        referencestatus: this.filter.referencestatus,
                        nodate: this.filter.nodate,
                        paydate: this.filter.paydate,
                        paydate_to: this.filter.paydate_to,
                        expdate_from: this.filter.expdate_from,
                        expdate_to: this.filter.expdate_to,
                        otherstatus: this.filter.otherstatus,
                        inventoryMode: this.filter.inventoryMode,
                        id: data.id
                    }
                }).catch(() => {})
            },
            printData(data) {
                this.$router.replace({
                    path: 'cloth-print-view-loss',
                    query: {
                        page: this.filter.page,
                        reverse: this.filter.reverse,
                        sort: this.filter.sort,
                        notype: this.filter.notype,
                        nofrom: this.filter.nofrom,
                        noto: this.filter.noto,
                        no: this.filter.no,
                        name: this.filter.name,
                        address: this.filter.address,
                        loan: this.filter.loan,
                        shop: this.filter.shop,
                        item: this.filter.item,
                        startdate: this.filter.startdate,
                        enddate: this.filter.enddate,
                        status: this.filter.status,
                        referencestatus: this.filter.referencestatus,
                        nodate: this.filter.nodate,
                        paydate: this.filter.paydate,
                        paydate_to: this.filter.paydate_to,
                        expdate_from: this.filter.expdate_from,
                        expdate_to: this.filter.expdate_to,
                        otherstatus: this.filter.otherstatus,
                        inventoryMode: this.filter.inventoryMode,
                        id: data.id
                    }
                }).catch(() => {})
            },
            deleteData(data) {
                this.id = data.id
            },
            async deleteMortgage() {
                this.isLoading = true
                let option = {
                    id: this.id,
                }
                await this.mortgageClothDeleteAction({
                    ...option
                }).then(res => {
                    this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
                }).catch(err => this.isLoading = true)
            },

        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.notype = this.$route.query.notype
            this.filter.nofrom = this.$route.query.nofrom
            this.filter.noto = this.$route.query.noto
            this.filter.no = this.$route.query.no
            this.filter.name = this.$route.query.name
            this.filter.address = this.$route.query.address
            this.filter.loan = this.$route.query.loan
            this.filter.shop = this.$route.query.shop
            this.filter.item = this.$route.query.item
            this.filter.startdate = this.$route.query.startdate
            this.filter.enddate = this.$route.query.enddate
            this.filter.status = this.$route.query.status
            this.filter.referencestatus = this.$route.query.referencestatus
            this.filter.nodate = this.$route.query.nodate
            this.filter.paydate = this.$route.query.paydate
            this.filter.paydate_to = this.$route.query.paydate_to
			this.filter.expdate_from = this.$route.query.expdate_from
			this.filter.expdate_to = this.$route.query.expdate_to
            this.filter.otherstatus = this.$route.query.otherstatus
            this.filter.inventoryMode = this.$route.query.inventoryMode
            this.fetchData()
            this.calcCount()
            this.getFields()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>